const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register(
        import.meta.env.PROD ? '/sw.js' : '/dev-sw.js?dev-sw',
        {
          type: import.meta.env.PROD ? 'classic' : 'module',
        }
      );
    });
  }
};

export default registerServiceWorker;
