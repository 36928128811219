/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { PageLoader } from 'app/components';
import router from 'app/router/Router';
import localforage from 'localforage';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import initAppInsights from './appInsights';
import handleMissingChunk from './missingChunkHandler';
import setupPullDownToRefresh from './pullDownToRefresh';
import reportWebVitals from './reportWebVitals';
import registerServiceWorker from './sw/registerSW';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <Suspense fallback={<PageLoader />}>
      <RouterProvider router={router} />
    </Suspense>
  </React.StrictMode>
);

//// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
registerServiceWorker();
initAppInsights();
setupPullDownToRefresh();
handleMissingChunk();
localforage.createInstance({
  driver: [localforage.INDEXEDDB],
  name: 'NotificationDb',
});
