import PullToRefresh from 'pulltorefreshjs';

const setupPullDownToRefresh = () => {
  const standalone = window.matchMedia('(display-mode: standalone)').matches;
  const isMobile = window.matchMedia('(max-width: 768px)').matches;
  const ios = /iPad|iPhone|iPod/.test(navigator.userAgent);
  if (standalone && isMobile && ios) {
    PullToRefresh.init({
      distIgnore: 50,
      instructionsRefreshing: ' ',
      instructionsPullToRefresh: ' ',
      instructionsReleaseToRefresh: ' ',
      iconArrow: `
        <div class="flex items-end justify-center w-full">
          <svg
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.25 10C0.835786 10 0.497073 10.3363 0.528107 10.7493C0.656881 12.4632 1.22574 14.1196 2.1853 15.5557C3.28412 17.2002 4.8459 18.4819 6.67316 19.2388C8.50043 19.9957 10.5111 20.1937 12.4509 19.8079C14.3907 19.422 16.1725 18.4696 17.5711 17.0711C18.9696 15.6725 19.922 13.8907 20.3079 11.9509C20.6937 10.0111 20.4957 8.00043 19.7388 6.17317C18.9819 4.34591 17.7002 2.78412 16.0557 1.6853C14.6196 0.725744 12.9632 0.156881 11.2493 0.0281075C10.8363 -0.00292692 10.5 0.335786 10.5 0.75C10.5 1.16421 10.8364 1.49656 11.2491 1.53306C12.6659 1.6584 14.033 2.13783 15.2223 2.93251C16.6202 3.8665 17.7096 5.19402 18.353 6.74719C18.9963 8.30036 19.1646 10.0094 18.8367 11.6583C18.5087 13.3071 17.6992 14.8217 16.5104 16.0104C15.3217 17.1992 13.8071 18.0087 12.1583 18.3367C10.5094 18.6646 8.80036 18.4963 7.24719 17.853C5.69402 17.2096 4.3665 16.1202 3.43251 14.7223C2.63783 13.533 2.1584 12.1659 2.03306 10.7491C1.99656 10.3364 1.66421 10 1.25 10Z"
              fill="currentColor"
            />
          </svg>
        </div>`,
      iconRefreshing: `
        <div class="flex items-end justify-center w-full">
          <svg
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="animate-spin"
          >
            <path
              d="M1.25 10C0.835786 10 0.497073 10.3363 0.528107 10.7493C0.656881 12.4632 1.22574 14.1196 2.1853 15.5557C3.28412 17.2002 4.8459 18.4819 6.67316 19.2388C8.50043 19.9957 10.5111 20.1937 12.4509 19.8079C14.3907 19.422 16.1725 18.4696 17.5711 17.0711C18.9696 15.6725 19.922 13.8907 20.3079 11.9509C20.6937 10.0111 20.4957 8.00043 19.7388 6.17317C18.9819 4.34591 17.7002 2.78412 16.0557 1.6853C14.6196 0.725744 12.9632 0.156881 11.2493 0.0281075C10.8363 -0.00292692 10.5 0.335786 10.5 0.75C10.5 1.16421 10.8364 1.49656 11.2491 1.53306C12.6659 1.6584 14.033 2.13783 15.2223 2.93251C16.6202 3.8665 17.7096 5.19402 18.353 6.74719C18.9963 8.30036 19.1646 10.0094 18.8367 11.6583C18.5087 13.3071 17.6992 14.8217 16.5104 16.0104C15.3217 17.1992 13.8071 18.0087 12.1583 18.3367C10.5094 18.6646 8.80036 18.4963 7.24719 17.853C5.69402 17.2096 4.3665 16.1202 3.43251 14.7223C2.63783 13.533 2.1584 12.1659 2.03306 10.7491C1.99656 10.3364 1.66421 10 1.25 10Z"
              fill="currentColor"
            />
          </svg>
        </div>`,
    });
  }
};

export default setupPullDownToRefresh;
