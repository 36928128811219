import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';

import config from './app/config';

const initAppInsights = (): void => {
  const browserHistory = createBrowserHistory({ window: window });

  const reactPlugin = new ReactPlugin();

  const clickPluginInstance = new ClickAnalyticsPlugin();

  const clickPluginConfig = {
    autoCapture: true,
  };

  const appInsights = new ApplicationInsights({
    config: {
      connectionString: config.env.appInsightsKey,
      extensions: [reactPlugin, clickPluginInstance],
      enableAutoRouteTracking: true,
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
        [clickPluginInstance.identifier]: clickPluginConfig,
      },
    },
  });

  appInsights.loadAppInsights();
  appInsights.trackPageView();
};

export default initAppInsights;
