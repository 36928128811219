const handleMissingChunk = () => {
  //https://vitejs.dev/guide/build#load-error-handling
  window.addEventListener('vite:preloadError', (event) => {
    event.preventDefault();

    const attempts = localStorage.getItem('chunkReload') || 0;

    if (Number(attempts) >= 3) {
      localStorage.setItem('chunkReload', '0');
      window.location.href = '/';
      return;
    }

    localStorage.setItem('chunkReload', (Number(attempts) + 1).toString());
    window.location.reload();
  });
};

export default handleMissingChunk;
